import React from 'react'
import Layout from "../components/general/Layout";
import ExpertiseGrid from '../components/praktijkgebieden/ExpertiseGrid';
import IntroGebieden from '../components/praktijkgebieden/IntroGebieden';

const PraktijkgebiedenPage = () => {
    return (
        <Layout>
            <IntroGebieden></IntroGebieden>
            <ExpertiseGrid></ExpertiseGrid>
        </Layout>
    )
}

export default PraktijkgebiedenPage
