import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const IntroGebieden = () => {
  return (
    <div className="bg-gray-white font-inter py-36">
      <div className="grid grid-cols-12 w-full h-full">
        <div className="hidden 2xl:block col-span-0  2xl:col-span-2"></div>
        <div className="col-span-12  lg:col-span-6 2xl:col-span-4   py-24 px-16">
          <p className="text-3xl sm:text-5xl font-semibold text-black ">
            
          </p>
        </div>

        <div className="col-span-12 lg:col-span-6 2xl:col-span-4  bg-gray-white">
          <StaticImage
            src="https://res.cloudinary.com/yungnuts/image/upload/v1643545590/CVT/samen_sturg8.jpg"
            alt="Mooie foto"
            className="h-full
            "
          />
        </div>
        <div className="col-span-0 2xl:col-span-2 "></div>
      </div>
    </div>
  );
};

export default IntroGebieden;
